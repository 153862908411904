import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from 'modules/auth/hooks/useAuth';

export function RootRoute(): ReactElement {
  useAuth();

  return <Outlet />;
}
