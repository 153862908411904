export function memoizePromise<TResult, TObject, TArgObject>(
  fn: (obj: TObject, argObj: TArgObject) => Promise<TResult>,
  keyGenerator: (obj: TObject, argObj: TArgObject) => string,
): (obj: TObject, argObj: TArgObject) => Promise<TResult> {
  // Map to store cached promises with the generated key
  const cache = new Map<string, Promise<TResult>>();

  return (obj: TObject, argObj: TArgObject): Promise<TResult> => {
    // Generate a cache key using the key generator function
    const key = keyGenerator(obj, argObj);

    // If the key already exists in the cache, return the cached promise
    if (cache.has(key)) {
      return cache.get(key)!;
    }

    // Otherwise, create and cache the new promise
    const promise = fn(obj, argObj);
    cache.set(key, promise);

    // Remove the cached promise in case of rejection to allow retry
    promise.catch(() => cache.delete(key));

    return promise;
  };
}
