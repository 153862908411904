import { Address } from 'web3';

import { ReadProvider } from '../../api';
import StakingAbi from '../abi/staking';

export function createStakingContract(
  provider: ReadProvider,
  { address }: { address: Address },
) {
  return provider.createContract<typeof StakingAbi>(StakingAbi, address);
}
