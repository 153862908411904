import { Components, inputBaseClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function getMuiSelect(theme: Theme): Components['MuiSelect'] {
  return {
    defaultProps: {
      IconComponent: props => (
        <svg
          {...props}
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 9L12 15L19 9" stroke="#82899A" strokeWidth="1.5" />
        </svg>
      ),
    },
    styleOverrides: {
      root: {
        [`&.${inputBaseClasses.sizeSmall}`]: {
          height: 'auto',
          minHeight: 28,
          borderRadius: 10,
        },
      },

      select: {
        color: theme.palette.text.primary,
        fontWeight: 400,
      },

      icon: {
        color: theme.palette.text.secondary,
      },
    },
  };
}
