import { alertClasses, Components, svgIconClasses, Theme } from '@mui/material';

export function getMuiAlert(theme: Theme): Components['MuiAlert'] {
  return {
    styleOverrides: {
      root: {
        [`&.${alertClasses.colorInfo}`]: {
          '&&': {
            backgroundColor: theme.palette.info.light,

            [`& .${alertClasses.message}, & .${alertClasses.icon}`]: {
              color: theme.palette.info.main,
            },
          },
        },

        [`&.${alertClasses.colorSuccess}`]: {
          '&&': {
            backgroundColor: theme.palette.success.light,

            [`& .${alertClasses.message}, & .${alertClasses.icon}`]: {
              color: theme.palette.success.main,
            },
          },
        },

        [`&.${alertClasses.colorError}`]: {
          '&&': {
            backgroundColor: theme.palette.error.light,

            [`& .${alertClasses.message}, & .${alertClasses.icon}`]: {
              color: theme.palette.error.main,
            },
          },
        },

        [`&>.${alertClasses.icon}>.${svgIconClasses.root}`]: {
          color: 'inherit',
        },
      },
    },
  };
}
