import { JSX, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ExternalLink, OverlaySpinner } from '@ankr.com/ui';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import BigNumber from 'bignumber.js';

import { useGetProjectQuery } from 'modules/project/actions/getProject';

import { chainId } from '../../../api/chainIDs';
import { SwitchNetworkGuard } from '../../../auth/components/SwitchNetworkGuard';
import { WalletConnectGuard } from '../../../auth/components/WalletConnectGuard';
import { getTransactionDetailsLink } from '../../../common';
import videoUrl from '../../../common/assets/success.mp4';
import { CopyButton } from '../../../common/components/CopyButton';
import { cropString } from '../../../common/utils/cropString';
import { DashboardRoutesConfig } from '../../../dashboard/Routes';
import { useTranslation } from '../../../i18n/hooks/useTranslation';
import { useContributeMutation } from '../../actions/contribute';
import { useGetBalanceQuery } from '../../actions/getBalance';
import { useGetTokenRewardsMultiplierQuery } from '../../actions/getTokenRewardsMultiplier';
import { useWaitTransactionDetailsMutation } from '../../actions/getTransactionDetails';
import {
  CONTRIBUTE_TOKEN_SYMBOL,
  ContributeForm,
  IContributePayload,
} from '../ContributeForm';
import { translation } from './translation';
import { useContributeModalStyles } from './useContributeModalStyles';

interface IContributeModalProps {
  projectId: number;
  open: boolean;
  onClose?: () => void;
}

export function ContributeModal({
  projectId,
  open = false,
  onClose,
}: IContributeModalProps): JSX.Element {
  const { classes } = useContributeModalStyles();
  const { t, keys } = useTranslation(translation);

  const { data: project, isLoading: isProjectLoading } =
    useGetProjectQuery(projectId);

  const investTokenAddress = project?.attributes?.investTokenAddress || '';
  const projectAddress = project?.attributes?.projectAddress || '';

  const { data: getBalanceData, isLoading: isBalanceDataLoading } =
    useGetBalanceQuery(
      investTokenAddress
        ? {
            tokenAddress: investTokenAddress,
          }
        : skipToken,
    );

  const [contribute, { data: contributeData, isLoading: isContributeLoading }] =
    useContributeMutation();

  const [
    getTransactionDetails,
    { isLoading: isTransactionLoading, isSuccess: isTransactionSuccess },
  ] = useWaitTransactionDetailsMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm<IContributePayload>({
    defaultValues: {
      contributeAmount: '',
    },
  });

  const { data: getTokenRewardsMultiplierData } =
    useGetTokenRewardsMultiplierQuery(
      projectAddress
        ? {
            projectAddress,
          }
        : skipToken,
    );

  const onSubmit = useCallback(
    async ({ contributeAmount }: IContributePayload) => {
      if (projectAddress) {
        try {
          const { receiptPromise } = await contribute({
            projectAddress,
            amount: new BigNumber(contributeAmount),
          }).unwrap();

          await getTransactionDetails(receiptPromise).unwrap();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }
    },
    [contribute, getTransactionDetails, projectAddress],
  );

  const onMax = useCallback(() => {
    if (getBalanceData) {
      setValue('contributeAmount', getBalanceData.value.toNumber(), {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [getBalanceData, setValue]);

  const renderedDetails = useMemo(() => {
    if (!contributeData) {
      return null;
    }

    return (
      <Box className={classes.details}>
        <Box className={classes.listBody}>
          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.amount)}</Box>

            <Box className={classes.listRowRight}>
              {t(keys.tokensAmount, {
                value: contributeData.amount.toString(),
                tokenSymbol: CONTRIBUTE_TOKEN_SYMBOL,
              })}
            </Box>
          </Box>

          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>
              {t(keys.destinationAddress)}
            </Box>

            <Box className={classes.listRowRight}>
              <Box alignItems="center" display="flex" gap={2}>
                {cropString(contributeData.projectAddress, 8)}

                <CopyButton size="small" text={contributeData.projectAddress} />
              </Box>
            </Box>
          </Box>

          <Box className={classes.listRow}>
            <Box className={classes.listRowLeft}>{t(keys.txID)}</Box>

            <Box className={classes.listRowRight}>
              <Box alignItems="center" display="flex" gap={2}>
                {cropString(contributeData.transactionHash, 8)}

                <CopyButton
                  size="small"
                  text={contributeData.transactionHash}
                />

                <IconButton
                  component="a"
                  href={getTransactionDetailsLink(
                    contributeData.transactionHash,
                  )}
                  rel="noreferrer"
                  size="small"
                  target="_blank"
                >
                  <ExternalLink />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }, [classes, contributeData, keys, t]);

  const renderedPending = useMemo(() => {
    return (
      <>
        <Box className={classes.text}>
          <Typography component="div" variant="h5">
            {t(keys.pendingTitle)}
          </Typography>

          <Typography
            className={classes.subTitle}
            component="div"
            variant="body2"
          >
            {t(keys.pendingSubtitle)}
          </Typography>
        </Box>

        {renderedDetails}

        <Button
          fullWidth
          component={Link}
          size="large"
          to={DashboardRoutesConfig.dashboard.generatePath()}
          onClick={onClose}
        >
          {t(keys.goToDashboard)}
        </Button>
      </>
    );
  }, [
    classes.subTitle,
    classes.text,
    keys.goToDashboard,
    keys.pendingSubtitle,
    keys.pendingTitle,
    onClose,
    renderedDetails,
    t,
  ]);

  const renderedSuccess = useMemo(() => {
    return (
      <>
        <video autoPlay muted playsInline className={classes.video}>
          <source src={videoUrl} type="video/mp4" />
        </video>

        <Box className={classes.text}>
          <Typography component="div" variant="h5">
            {t(keys.successTitle)}
          </Typography>
        </Box>

        {renderedDetails}

        <Button
          fullWidth
          component={Link}
          size="large"
          to={DashboardRoutesConfig.dashboard.generatePath()}
          onClick={onClose}
        >
          {t(keys.goToDashboard)}
        </Button>
      </>
    );
  }, [
    classes.text,
    classes.video,
    keys.goToDashboard,
    keys.successTitle,
    onClose,
    renderedDetails,
    t,
  ]);

  const render = useMemo(() => {
    if (isBalanceDataLoading || isProjectLoading) {
      return <OverlaySpinner sx={{ margin: 'auto' }} />;
    }

    if (isTransactionLoading) {
      return renderedPending;
    }

    if (isTransactionSuccess) {
      return renderedSuccess;
    }

    return (
      <ContributeForm
        balance={getBalanceData?.value}
        companyName={
          project?.attributes?.projectCompany?.data?.attributes.companyName
        }
        control={control}
        imoTokenSymbol={project?.attributes?.imoTokenSymbol || ''}
        isDirty={isDirty}
        isLoading={isContributeLoading}
        multiplier={getTokenRewardsMultiplierData?.multiplier ?? BigNumber(0)}
        projectName={project?.attributes?.projectName || ''}
        watch={watch}
        onMax={onMax}
        onSubmit={handleSubmit(onSubmit)}
      />
    );
  }, [
    control,
    getBalanceData?.value,
    getTokenRewardsMultiplierData?.multiplier,
    handleSubmit,
    isBalanceDataLoading,
    isContributeLoading,
    isDirty,
    isProjectLoading,
    isTransactionLoading,
    isTransactionSuccess,
    onMax,
    onSubmit,
    project?.attributes,
    renderedPending,
    renderedSuccess,
    watch,
  ]);

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
    >
      <WalletConnectGuard>
        <SwitchNetworkGuard chainId={chainId}>
          <DialogContent className={classes.content}>{render}</DialogContent>
        </SwitchNetworkGuard>
      </WalletConnectGuard>
    </Dialog>
  );
}
