import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { DashboardRoutesConfig } from 'modules/dashboard/Routes';
import { Links } from 'modules/layout/components/Links/Links';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { Account } from '../Account';
import { useStyles } from './useStyles';

interface Props {
  className?: string;
}

export function Header({ className }: Props): ReactElement {
  const { classes, cx } = useStyles();

  const useIsSMDown = () => {
    const { breakpoints } = useTheme();
    return useMediaQuery(breakpoints.down('sm'));
  };

  const isSmDown = useIsSMDown();

  return (
    <header className={cx(classes.root, className)}>
      <Box className={classes.content}>
        <Link
          className={classes.link}
          to={DashboardRoutesConfig.dashboard.generatePath()}
        >
          <LogoIcon />
        </Link>

        {!isSmDown && <Links className={classes.links} />}

        <Account />
      </Box>

      {isSmDown && <Links className={classes.linksXs} />}
    </header>
  );
}
