import { IRawTier } from '@ankr.com/points';

import { api, cacheTags, PointsApi } from 'modules/api';
import { getExtendedErrorText } from 'modules/common/utils/getExtendedErrorText';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { t } from 'modules/i18n';

export const { useGetTiersQuery } = api.injectEndpoints({
  endpoints: build => ({
    getTiers: build.query<IRawTier[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IRawTier[]>(
        async () => {
          const api = PointsApi.getInstance();

          return { data: await api.getTiers() };
        },
        error => getExtendedErrorText(error, t('requestError.getTiers')),
      ),
      providesTags: [cacheTags.account, cacheTags.points],
    }),
  }),
});
