import React, { ReactElement } from 'react';

import { ProjectRow } from 'modules/dashboard/components/ProjectRow';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { IProjectItem } from '../../../project/api/types';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface IProjectsTableProps {
  projects: IProjectItem[];
}

export function ProjectsTable({ projects }: IProjectsTableProps): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>{t(keys.project)}</th>

            <th>{t(keys.myContribution)}</th>

            <th>{t(keys.apy)}</th>

            <th>{t(keys.reward)}</th>
          </tr>
        </thead>

        <tbody>
          {projects.map(project => (
            <ProjectRow key={project.id} project={project} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
