import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { getUserContributionAmount } from '../api/getUserContributionAmount';
import { getUserRewardAmount } from '../api/getUserRewardAmount';

interface IGetUserProjectArgs {
  projectAddress: Address;
}

export interface IGetUserProjectData {
  contributionAmount: BigNumber;
  rewardAmount: BigNumber;
}

export const {
  useGetUserProjectQuery,
  endpoints: { getUserProject },
} = api.injectEndpoints({
  endpoints: build => ({
    getUserProject: build.query<IGetUserProjectData, IGetUserProjectArgs>({
      queryFn: async ({ projectAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        const { contributionAmount } = await getUserContributionAmount(
          readProvider,
          {
            projectAddress,
            contributorAddress: writeProvider.currentAccount,
          },
        );

        const { rewardAmount } = await getUserRewardAmount(readProvider, {
          projectAddress,
          contributorAddress: writeProvider.currentAccount,
        });

        return { data: { contributionAmount, rewardAmount } };
      },
      providesTags: [cacheTags.projects],
    }),
  }),
});

export interface IUserProjectStats {
  contributionAmount: BigNumber;
  activeProjectCount: number;
}

export function reduceUserProjects(
  items: (undefined | IGetUserProjectData)[],
): IUserProjectStats {
  return items
    .filter(item => item && !item.contributionAmount.isZero())
    .reduce(
      (acc, item) => {
        return {
          contributionAmount: acc.contributionAmount.plus(
            item ? item.contributionAmount : new BigNumber(0),
          ),
          activeProjectCount: acc.activeProjectCount + 1,
        };
      },
      {
        contributionAmount: new BigNumber(0),
        activeProjectCount: 0,
      },
    );
}
