import { ReactElement } from 'react';

import programLgIcon from 'modules/dashboard/assets/program-bg-lg.jpg';
import programMdIcon from 'modules/dashboard/assets/program-bg-md.jpg';

import { useStyles } from './useStyles';

interface IBackgroundProps {
  className?: string;
}

export function Background({ className }: IBackgroundProps): ReactElement {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <img
        alt=""
        className={cx(classes.background, classes.backgroundMd)}
        src={programMdIcon}
      />

      <img
        alt=""
        className={cx(classes.background, classes.backgroundLg)}
        src={programLgIcon}
      />
    </div>
  );
}
