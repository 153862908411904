import { Components, Theme } from '@mui/material';

export function getMuiDialog(theme: Theme): Components['MuiDialog'] {
  return {
    styleOverrides: {
      paper: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
      },
    },
  };
}
