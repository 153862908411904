import { useCallback, useMemo } from 'react';
import { IPointTier, PointTier } from '@ankr.com/points';

import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { translation } from './translation';

export function useApplyTierAssets(tiers: IPointTier[]): IPointTier[] {
  const { t, keys } = useTranslation(translation);

  const getTierAssets = useCallback(
    (tier: IPointTier): Pick<IPointTier, 'name' | 'color' | 'descriptions'> => {
      switch (tier.id) {
        case PointTier.NoTier:
          return {
            name: t(keys.noTier),
            color: 'transparent',
            descriptions: [
              {
                text: t(keys.noTier1),
              },
              {
                text: t(keys.noTier2),
                isDisabled: true,
              },
              {
                text: t(keys.noTier3),
                isDisabled: true,
              },
            ],
          };
        case PointTier.Bronze:
          return {
            name: t(keys.bronze),
            color: '#F84D05',
            descriptions: [
              {
                text: t(keys.bronze1),
              },
              {
                text: t(keys.bronze2),
              },
              {
                text: t(keys.bronze3),
                isDisabled: true,
              },
            ],
          };
        case PointTier.Silver:
          return {
            name: t(keys.silver),
            color: '#8B98B1',
            descriptions: [
              {
                text: t(keys.silver1),
              },
              {
                text: t(keys.silver2),
              },
              {
                text: t(keys.silver3),
              },
            ],
          };
        case PointTier.Gold:
          return {
            name: t(keys.gold),
            color: '#FCA905',
            descriptions: [
              {
                text: t(keys.gold1),
              },
              {
                text: t(keys.gold2),
              },
              {
                text: t(keys.gold3),
              },
            ],
          };
        default:
          return {
            name: t(keys.unknown),
            color: 'transparent',
            descriptions: [],
          };
      }
    },
    [
      keys.bronze,
      keys.bronze1,
      keys.bronze2,
      keys.bronze3,
      keys.gold,
      keys.gold1,
      keys.gold2,
      keys.gold3,
      keys.noTier,
      keys.noTier1,
      keys.noTier2,
      keys.noTier3,
      keys.silver,
      keys.silver1,
      keys.silver2,
      keys.silver3,
      keys.unknown,
      t,
    ],
  );

  return useMemo(
    () => tiers.map(tier => ({ ...tier, ...getTierAssets(tier) })),
    [getTierAssets, tiers],
  );
}
