import { Address } from 'web3';

import { ReadProvider, WriteProvider } from '../../api';
import IMOSaleAbi from './abi/IMOSale';

export function createImoContract(
  provider: ReadProvider | WriteProvider,
  { address }: { address: Address },
) {
  return provider.createContract<typeof IMOSaleAbi>(IMOSaleAbi, address);
}
