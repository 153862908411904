import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';
import { getContributionToken } from './getContributionToken';
import { getContributionTokenAmount } from './getContributionTokenAmount';

export async function getContribution(
  provider: ReadProvider,
  { projectAddress }: { projectAddress: Address },
) {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const { decimals, symbol, investTokenAddress } = await getContributionToken(
    provider,
    {
      projectAddress,
    },
  );

  const { amount } = await getContributionTokenAmount(provider, {
    projectAddress,
  });

  const fundingGoalResponse: bigint = await IMOSaleContract.methods
    .fundingGoal()
    .call();

  const fundingEndTimeResponse: bigint = await IMOSaleContract.methods
    .fundingEndTime()
    .call();

  const isActive = !(await IMOSaleContract.methods.isEmergencyStopped().call());

  return {
    amount,
    investmentGoal: new BigNumber(utils.fromWei(fundingGoalResponse, decimals)),
    investmentEndTime: new Date(Number(fundingEndTimeResponse * 1000n)),
    investTokenAddress,
    isActive,
    symbol,
  };
}
