import { Address } from 'web3';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

export async function getContributorCount(
  provider: ReadProvider,
  { projectAddress }: { projectAddress: Address },
) {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  return {
    contributorCount: Number(
      await IMOSaleContract.methods.getInvestorsCount().call(),
    ),
  };
}
