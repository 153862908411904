import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';

export async function getUserRewardAmount(
  provider: ReadProvider,
  {
    projectAddress,
    contributorAddress,
  }: { projectAddress: Address; contributorAddress: Address },
): Promise<{
  rewardAmount: BigNumber;
}> {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const rewards: bigint = await IMOSaleContract.methods
    .getActualTokenAllocation(contributorAddress)
    .call();

  return {
    rewardAmount: new BigNumber(utils.fromWei(rewards.toString(), 'ether')),
  };
}
