import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Plus } from '@ankr.com/ui';
import { IconButton, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import BigNumber from 'bignumber.js';

import { KnownDialogs, useDialog } from 'modules/dialogs';
import { globalTranslation } from 'modules/i18n';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';
import { ContributeModal } from 'modules/project/components/ContributeModal';
import { ProjectRoutesConfig } from 'modules/project/projectRoutesConfig';

import { useGetUserProjectQuery } from '../../../project/actions/getUserProject';
import { IProjectItem } from '../../../project/api/types';
import { CONTRIBUTE_TOKEN_SYMBOL } from '../../../project/components/ContributeForm';
import { useStyles } from './useStyles';

interface IProjectRowProps {
  project: IProjectItem;
}

export function ProjectRow({
  project: { attributes, id },
}: IProjectRowProps): ReactNode {
  const { t, keys } = useTranslation(globalTranslation);
  const { classes } = useStyles();

  const { onClose, isOpened, onOpen } = useDialog(KnownDialogs.contribute);

  const name = attributes.projectName;
  const company = attributes.projectCompany?.data?.attributes.companyName;
  const logo = attributes.projectGallery?.data?.[0]?.attributes.url;

  const { data: userProjectData, isLoading: isUserProjectLoading } =
    useGetUserProjectQuery(
      attributes.projectAddress
        ? {
            projectAddress: attributes.projectAddress,
          }
        : skipToken,
    );

  if (
    isUserProjectLoading ||
    !userProjectData ||
    userProjectData.contributionAmount.isZero()
  ) {
    return null;
  }

  const apy = 0;
  const { contributionAmount } = userProjectData;
  const contributionAmountUsd = new BigNumber(0);
  const tokensAmount = userProjectData.rewardAmount;
  const tokensAmountUsd = new BigNumber(0);

  return (
    <tr className={classes.root}>
      <td className={classes.projectColumn}>
        <Link
          className={classes.logoContainer}
          to={ProjectRoutesConfig.project.generatePath(id)}
        >
          <img alt="" className={classes.logo} src={logo} />
        </Link>

        <div>
          <Typography component="div" variant="subtitle1">
            {name}
          </Typography>

          {company && (
            <Typography component="div" variant="body3">
              {company}
            </Typography>
          )}
        </div>
      </td>

      <td>
        <div>
          <Typography component="div" variant="body3">
            {t(keys.unit.tokenValue, {
              token: CONTRIBUTE_TOKEN_SYMBOL,
              value: contributionAmount.decimalPlaces(2).toString(),
            })}
          </Typography>

          {contributionAmountUsd && !contributionAmountUsd?.isZero() && (
            <Typography className={classes.usdAmount} component="div">
              {t(keys.unit.$, {
                value: contributionAmountUsd.decimalPlaces(2).toString(),
              })}
            </Typography>
          )}
        </div>

        <IconButton
          className={classes.iconButton}
          sx={{ ml: 2 }}
          onClick={() => onOpen()}
        >
          <Plus htmlColor="inherit" />
        </IconButton>
      </td>

      <td>
        <Typography component="div" variant="body3">
          {apy ? t(keys.unit.percent, { value: apy }) : '-'}
        </Typography>
      </td>

      <td>
        <Typography component="div" variant="body3">
          {t(keys.unit.tokenValue, {
            token: attributes.imoTokenSymbol,
            value: tokensAmount.decimalPlaces(2).toString(),
          })}
        </Typography>

        {tokensAmountUsd && !contributionAmountUsd?.isZero() && (
          <Typography className={classes.usdAmount} component="div">
            {t(keys.unit.$, {
              value: tokensAmountUsd.decimalPlaces(2).toString(),
            })}
          </Typography>
        )}
      </td>

      {isOpened && (
        <ContributeModal
          open={isOpened}
          projectId={id}
          onClose={() => onClose()}
        />
      )}
    </tr>
  );
}
