import { Components, radioClasses, Theme } from '@mui/material';

export function getMuiRadio(theme: Theme): Components['MuiRadio'] {
  return {
    styleOverrides: {
      root: {
        '--radio-background': theme.palette.background.paper,
        '--radio-border': theme.palette.text.secondary,
        '--radio-checked': 'unset',

        [`&.${radioClasses.checked}`]: {
          '--radio-background': theme.palette.primary.main,
          '--radio-border': theme.palette.primary.main,
          '--radio-checked': theme.palette.background.paper,
        },
      },
    },
  };
}
