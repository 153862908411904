import { PointTier } from '@ankr.com/points';
import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { ReadProvider } from '../../api';
import { getUserCurrentTier } from '../../staking';
import { createImoContract } from './createImoContract';

export async function getTokenRewardsMultiplier(
  provider: ReadProvider,
  {
    contributorAddress,
    projectAddress,
  }: {
    contributorAddress: Address;
    projectAddress: Address;
  },
) {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const stakingContractAddress = await IMOSaleContract.methods
    .stakingContract()
    .call();

  const { tier } = await getUserCurrentTier(provider, {
    stakingContractAddress,
    stakerAddress: contributorAddress,
  });

  async function getMultiplier() {
    if (tier === PointTier.NoTier) {
      return new BigNumber(0);
    }

    const multiplier = await IMOSaleContract.methods
      .tokenAllocationMultiplier()
      .call();

    return new BigNumber(multiplier.toString());
  }

  return {
    multiplier: await getMultiplier(),
  };
}
