import { mainTheme as ankrTheme } from '@ankr.com/ui';
import { PaletteMode, ThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import { deepmerge } from '@mui/utils';

import { getMuiAutocomplete } from 'modules/themes/components/autocomplete';
import { getMuiFormLabel } from 'modules/themes/components/formLabel';
import { getMuiSelect } from 'modules/themes/components/select';

import { getMuiAlert } from './components/alert';
import { getMuiBackdrop } from './components/backdrop';
import { getMuiButton } from './components/button';
import { getMuiButtonBase } from './components/buttonBase';
import { getMuiChip } from './components/chip';
import { getMuiContainer } from './components/container';
import { getMuiCssBaseline } from './components/cssBaseline';
import { getMuiDialog } from './components/dialog';
import { getMuiDrawer } from './components/drawer';
import { getMuiIconButton } from './components/iconButton';
import { getMuiInputAdornment } from './components/inputAdornment';
import { getMuiInputBase } from './components/inputBase';
import { getMuiMenuItem } from './components/menuItem';
import { getMuiPaper } from './components/paper';
import { getMuiPopper } from './components/popper';
import { getMuiRadio } from './components/radio';
import { getMuiSvgIcon } from './components/svgIcon';
import { getMuiTextField } from './components/textField';
import { getMuiTypography } from './components/typography';
import { mainPalette } from './palette';

const DEFAULT_MODE: PaletteMode = 'light';

export function createMainTheme() {
  const baseTheme = createMuiTheme(
    deepmerge(ankrTheme, {
      palette: createPalette({
        ...mainPalette,
        mode: DEFAULT_MODE,
      }),
    }),
  );

  const options: ThemeOptions = {
    components: {
      MuiCssBaseline: getMuiCssBaseline(baseTheme),
      MuiTypography: getMuiTypography(),
      MuiBackdrop: getMuiBackdrop(baseTheme),
      MuiDialog: getMuiDialog(baseTheme),
      MuiDrawer: getMuiDrawer(baseTheme),
      MuiContainer: getMuiContainer(baseTheme),
      MuiButtonBase: getMuiButtonBase(),
      MuiButton: getMuiButton(baseTheme),
      MuiIconButton: getMuiIconButton(baseTheme),
      MuiInputBase: getMuiInputBase(baseTheme),
      MuiFormLabel: getMuiFormLabel(baseTheme),
      MuiTextField: getMuiTextField(),
      MuiInputAdornment: getMuiInputAdornment(),
      MuiSelect: getMuiSelect(baseTheme),
      MuiAutocomplete: getMuiAutocomplete(baseTheme),
      MuiSvgIcon: getMuiSvgIcon(baseTheme),
      MuiChip: getMuiChip(baseTheme),
      MuiAlert: getMuiAlert(baseTheme),
      MuiRadio: getMuiRadio(baseTheme),
      MuiPopper: getMuiPopper(),
      MuiPaper: getMuiPaper(baseTheme),
      MuiMenuItem: getMuiMenuItem(baseTheme),
    },
  };

  return createMuiTheme(deepmerge(baseTheme, options));
}

export const mainTheme = createMainTheme();
