import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    overflowX: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-8),
      padding: theme.spacing(0, 8),
      width: '100vw',
    },
  },

  table: {
    width: '100%',
    minWidth: 840,

    '& th': {
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },

    '& tr': {
      display: 'grid',
      gridTemplateColumns: '40.82% 20% 10.20% 1fr',
      marginBottom: theme.spacing(3),
    },
  },
}));
