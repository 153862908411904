import { makeStyles } from 'tss-react/mui';

export const useCopyButtonStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
    fontSize: 'inherit',
    color: theme.palette.text.secondary,
  },
  copiedTooltip: {
    color: theme.palette.success.main,
  },
  icon: {
    fontSize: 'inherit',
    width: '1em',
    height: '1em',
  },
  copyIcon: {},
  doneIcon: {
    '&&': {
      color: theme.palette.success.main,
    },
  },
}));
