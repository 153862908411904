import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import IERC20Abi from './abi/IERC20';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
const NATIVE_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';

export async function getBalance(
  provider: ReadProvider,
  data: { userAddress: Address; tokenAddress: string },
) {
  const { userAddress, tokenAddress } = data;
  const isNativeToken =
    tokenAddress === ZERO_ADDRESS || tokenAddress === NATIVE_TOKEN_ADDRESS;

  if (isNativeToken) {
    const balance = await provider.getWeb3().eth.getBalance(userAddress);
    return {
      value: new BigNumber(utils.fromWei(balance, 'ether')),
    };
  }

  const tokenContract = provider.createContract<typeof IERC20Abi>(
    IERC20Abi,
    tokenAddress,
  );

  const balance: bigint = await tokenContract.methods
    .balanceOf(userAddress)
    .call();

  const decimals = await tokenContract.methods.decimals().call();

  return {
    value: new BigNumber(utils.fromWei(balance, Number(decimals))),
  };
}
