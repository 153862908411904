import { ReactElement } from 'react';
import { Board, IPointTier } from '@ankr.com/points';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';

import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { Background } from '../Background';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface IProgramProps {
  className?: string;
  stakedAmount: BigNumber;
  pointsAmount: BigNumber;
  onStakeClick?: () => void;
  tiers: IPointTier[];
}

export function Program({
  className,
  stakedAmount,
  pointsAmount,
  onStakeClick,
  tiers,
}: IProgramProps): ReactElement {
  const { classes, cx } = useStyles();
  const { t, keys } = useTranslation(translation);

  return (
    <div className={cx(classes.root, className)}>
      <Background />

      <Board
        className={classes.board}
        pointsAmount={pointsAmount}
        stakedAmount={stakedAmount}
        tiers={tiers}
        title={
          <div className={classes.text}>
            <Typography fontWeight={500} variant="h3">
              {t(keys.title)}
            </Typography>

            <Typography
              className={classes.label}
              component="div"
              fontWeight={400}
              variant="h6"
            >
              {t(keys.label)}
            </Typography>
          </div>
        }
        onStakeClick={onStakeClick}
      />
    </div>
  );
}
