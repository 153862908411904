import { ReactElement } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { Typography } from '@mui/material';

import { DashboardRoutesConfig } from 'modules/dashboard/Routes';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { ProjectRoutesConfig } from '../../../project/projectRoutesConfig';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface Props {
  className?: string;
}

export function Links({ className }: Props): ReactElement {
  const { t, keys } = useTranslation(translation);
  const { classes, cx } = useStyles();
  const isDashboard = useMatch(DashboardRoutesConfig.dashboard.path);

  return (
    <div className={cx(classes.root, className)}>
      <NavLink
        className={({ isActive }) => {
          return isActive ? cx(classes.link, classes.active) : classes.link;
        }}
        to={ProjectRoutesConfig.projects.generatePath()}
      >
        <Typography component="span" fontWeight="inherit" variant="body2">
          {t(keys.projects)}
        </Typography>
      </NavLink>

      <NavLink
        className={({ isActive }) => {
          return isActive || isDashboard
            ? cx(classes.link, classes.active)
            : classes.link;
        }}
        to={DashboardRoutesConfig.dashboard.generatePath()}
      >
        <Typography component="span" fontWeight="inherit" variant="body2">
          {t(keys.dashboard)}
        </Typography>
      </NavLink>

      <NavLink
        className={({ isActive }) => {
          return isActive ? cx(classes.link, classes.active) : classes.link;
        }}
        to={ProjectRoutesConfig.createProject.generatePath()}
      >
        <Typography component="span" fontWeight="inherit" variant="body2">
          {t(keys.apply)}
        </Typography>
      </NavLink>
    </div>
  );
}
