import { Components } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

export function getMuiPopper(): Components['MuiPopper'] {
  return {
    styleOverrides: {
      root: {
        zIndex: zIndex.drawer,
      },
    },
  };
}
