import { Components, inputAdornmentClasses } from '@mui/material';

export function getMuiInputAdornment(): Components['MuiInputAdornment'] {
  return {
    styleOverrides: {
      root: {
        '& > img': {
          width: 24,
          height: 24,
        },

        [`&.${inputAdornmentClasses.sizeSmall} > img`]: {
          width: 16,
          height: 16,
        },
      },
    },
  };
}
