import { ReactElement, ReactNode } from 'react';

import { WalletsDialog } from 'modules/auth/components/WalletsDialog';

import { useScrollToTop } from '../../../common/hooks/useScrollToTop';
import { Header } from '../Header';
import { useStyles } from './useStyles';

interface Props {
  children: ReactElement | ReactNode;
}

export function Layout({ children }: Props): ReactElement {
  const { classes } = useStyles();
  useScrollToTop();

  return (
    <div className={classes.root}>
      <Header />

      <div className={classes.content}>{children}</div>

      <WalletsDialog />
    </div>
  );
}
