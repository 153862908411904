import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, cacheTags, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { getBalance } from '../api/getBalance';

interface IGetBalanceArgs {
  tokenAddress: Address;
}

interface IGetBalanceData {
  value: BigNumber;
}

export const { useGetBalanceQuery } = api.injectEndpoints({
  endpoints: build => ({
    getBalance: build.query<IGetBalanceData, IGetBalanceArgs>({
      queryFn: async ({ tokenAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        return {
          data: await getBalance(readProvider, {
            userAddress: writeProvider.currentAccount,
            tokenAddress,
          }),
        };
      },
      providesTags: [cacheTags.projects],
    }),
  }),
});
