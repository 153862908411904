import { PointTier } from '@ankr.com/points';
import { Address } from 'web3';

import { ReadProvider } from '../../api';
import { createStakingContract } from './createStakingContract';

function mapTier(value: number) {
  switch (value) {
    case 0:
      return PointTier.NoTier;
    case 1:
      return PointTier.Bronze;
    case 2:
      return PointTier.Silver;
    case 3:
      return PointTier.Gold;
    default:
      return PointTier.NoTier;
  }
}

export async function getUserCurrentTier(
  provider: ReadProvider,
  {
    stakingContractAddress,
    stakerAddress,
  }: { stakingContractAddress: Address; stakerAddress: Address },
) {
  const stakingContract = createStakingContract(provider, {
    address: stakingContractAddress,
  });

  const tier: Date = await stakingContract.methods
    .getCurrentTier(stakerAddress)
    .call();

  return {
    tier: mapTier(Number(tier)),
  };
}
