import { ReactElement } from 'react';
import { Cards } from '@ankr.com/points';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';
import { useGetAnkrStakedAmountQuery } from 'modules/dashboard/actions/getAnkrStakedAmount';
import { useGetPointTiers } from 'modules/dashboard/hooks/useGetPointTiers';
import { openAnkrStaking } from 'modules/dashboard/utils/openAnkrStaking';

import { useStyles } from './useStyles';

export function MembershipRank(): ReactElement {
  const { classes } = useStyles();
  const { isConnected } = useConnection();

  const tiers = useGetPointTiers();
  const { data: stakedAmount = ZERO } = useGetAnkrStakedAmountQuery(undefined, {
    skip: !isConnected,
  });

  return (
    <Cards
      className={classes.cards}
      stakedAmount={stakedAmount}
      tiers={tiers}
      onStakeClick={openAnkrStaking}
    />
  );
}
