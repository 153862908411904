import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },

  link: {
    height: 30,
    borderRadius: 8,
    padding: theme.spacing(0, 3),

    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    transition: 'color 0.3s, background-color 0.3s',
  },

  active: {
    backgroundColor: theme.palette.grey[800],
    fontWeight: 600,

    '&, &:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));
