import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    pendingTitle: 'Contribution is pending...',
    pendingSubtitle:
      'This may take a moment; you can close this window. Once completed, you can view your staking exposure on the dashboard.',
    successTitle: 'Contribution successful!',
    destinationAddress: 'Destination address',
    txID: 'Transaction ID',
    goToDashboard: 'Go to Dashboard',
    tokensAmount:
      '{value, number, ::.########} {tokenSymbol} {value, plural, =1 {Token} other {Tokens}}',
    amount: 'Amount',
  },
};
