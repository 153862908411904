import { inputBaseClasses } from '@mui/material/InputBase';
import { Components, Theme } from '@mui/material/styles';

export function getMuiInputBase(theme: Theme): Components['MuiInputBase'] {
  return {
    styleOverrides: {
      root: {
        height: 'auto',
        minHeight: 48,

        '&&': {
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        },

        [`&.${inputBaseClasses.multiline}`]: {
          height: 'auto',
          minHeight: 48,
          paddingTop: theme.spacing(2),
        },

        [`&&.${inputBaseClasses.focused}`]: {
          boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
        },

        [`&&.${inputBaseClasses.error}`]: {
          boxShadow: `0 0 0 2px ${theme.palette.error.main}`,
          color: theme.palette.error.main,
        },
      },
    },
  };
}
