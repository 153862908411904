import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7.5),
  },

  tabs: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },

  tab: {
    fontWeight: 500,

    height: 42,
    borderRadius: 12,
    padding: theme.spacing(0, 4),
    cursor: 'pointer',

    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    transition: 'color 0.3s, background-color 0.3s',

    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    },
  },

  activeTab: {
    backgroundColor: theme.palette.grey[800],
    fontWeight: 700,

    '&, &:hover': {
      color: theme.palette.secondary.main,
    },
  },

  tabsContent: {},
}));
