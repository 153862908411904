import React, { ReactElement, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Check, Copy } from '@ankr.com/ui';
import { IconButton, Tooltip } from '@mui/material';

import { useTranslation } from '../../../i18n/hooks/useTranslation';
import { translation } from './translation';
import { useCopyButtonStyles } from './useCopyButtonStyles';

const COPIED_TIMEOUT = 2000;

interface ICopyButtonProps {
  text?: string;
  disabled?: boolean;
  className?: string;
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  renderButton?: (isCopied: boolean) => ReactElement;
}

export function CopyButton({
  text,
  className,
  disabled,
  color = 'primary',
  size = 'medium',
  tooltipPlacement = 'bottom',
  renderButton,
}: ICopyButtonProps) {
  const { classes, cx } = useCopyButtonStyles();

  const { t, keys } = useTranslation(translation);

  const [isCopied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
        (document.activeElement as HTMLElement)?.blur();
      }, COPIED_TIMEOUT);
    }
  }, [isCopied]);

  if (!text) {
    return null;
  }

  function renderDefaultButton(isCopied: boolean) {
    return (
      <IconButton
        className={cx(classes.root, className)}
        color={color}
        disabled={disabled}
        size={size}
      >
        {isCopied ? (
          <Check
            className={cx(classes.icon, classes.doneIcon)}
            sx={{ color: 'inherit' }}
          />
        ) : (
          <Copy
            className={cx(classes.icon, classes.copyIcon)}
            sx={{ color: 'inherit' }}
          />
        )}
      </IconButton>
    );
  }

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <Tooltip
        enterNextDelay={0}
        placement={tooltipPlacement}
        title={
          isCopied ? (
            <span className={classes.copiedTooltip}>{t(keys.copied)}</span>
          ) : (
            t(keys.copyToClipboard)
          )
        }
      >
        {renderButton ? renderButton(isCopied) : renderDefaultButton(isCopied)}
      </Tooltip>
    </CopyToClipboard>
  );
}
