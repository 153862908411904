import BigNumber from 'bignumber.js';
import { Address } from 'web3';

import { api, getReadProvider, getWriteProvider } from 'modules/api';

import { chainId } from '../../api/chainIDs';
import { getTokenRewardsMultiplier } from '../api/getTokenRewardsMultiplier';

interface IGetTokenRewardsMultiplierArgs {
  projectAddress: Address;
}

interface IGetTokenRewardsMultiplierData {
  multiplier: BigNumber;
}

export const { useGetTokenRewardsMultiplierQuery } = api.injectEndpoints({
  endpoints: build => ({
    getTokenRewardsMultiplier: build.query<
      IGetTokenRewardsMultiplierData,
      IGetTokenRewardsMultiplierArgs
    >({
      queryFn: async ({ projectAddress }) => {
        const writeProvider = await getWriteProvider();
        const readProvider = await getReadProvider(chainId);

        return {
          data: await getTokenRewardsMultiplier(readProvider, {
            contributorAddress: writeProvider.currentAccount,
            projectAddress,
          }),
        };
      },
      providesTags: [],
    }),
  }),
});
