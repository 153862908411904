import { ReactElement } from 'react';
import { Container } from '@mui/material';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';
import { useGetAnkrStakedAmountQuery } from 'modules/dashboard/actions/getAnkrStakedAmount';
import { useGetPointsQuery } from 'modules/dashboard/actions/getPoints';
import { ConnectWallet } from 'modules/dashboard/components/ConnectWallet/ConnectWallet';
import { useGetPointTiers } from 'modules/dashboard/hooks/useGetPointTiers';
import { openAnkrStaking } from 'modules/dashboard/utils/openAnkrStaking';

import { Program } from '../../components/Program';
import { Tabs } from '../../components/Tabs';
import { useStyles } from './useStyles';

export function Dashboard(): ReactElement {
  const { classes } = useStyles();
  const { isConnected } = useConnection();

  const tiers = useGetPointTiers();
  const { data: stakedAmount = ZERO } = useGetAnkrStakedAmountQuery(undefined, {
    skip: !isConnected,
  });

  const { data: pointsAmount = ZERO } = useGetPointsQuery(undefined, {
    skip: !isConnected,
  });

  return (
    <Container>
      {isConnected ? (
        <div className={classes.root}>
          <Program
            pointsAmount={pointsAmount}
            stakedAmount={stakedAmount}
            tiers={tiers}
            onStakeClick={openAnkrStaking}
          />

          <Tabs />
        </div>
      ) : (
        <ConnectWallet />
      )}
    </Container>
  );
}
