import { buttonClasses, Components, Theme } from '@mui/material';

export const getMuiButton = (theme: Theme): Components['MuiButton'] => ({
  styleOverrides: {
    root: {
      verticalAlign: 'baseline',
    },
    outlined: {
      border: 0,

      [`&.${buttonClasses.colorPrimary}`]: {
        boxShadow: `0 0 0 2px ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    text: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});
