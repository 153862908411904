import React, { ReactElement } from 'react';
import {
  Button,
  ButtonPropsSizeOverrides,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { cropString } from 'modules/common/utils/cropString';
import { KnownDialogs, useDialog } from 'modules/dialogs';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { useConnection } from '../../../auth/hooks/useConnection';
import { translation } from './translation';
import { useStyles } from './useStyles';

interface IAccountProps {
  buttonClassName?: string;
  buttonSize?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
}

export function Account({
  buttonClassName,
  buttonSize = 'medium',
}: IAccountProps): ReactElement {
  const { keys, t } = useTranslation(translation);
  const { classes, cx } = useStyles();

  const { isConnected, address = '', disconnect, walletIcon } = useConnection();

  const { onOpen } = useDialog(KnownDialogs.connect);

  if (!isConnected) {
    return (
      <Button
        className={cx(classes.button, buttonClassName)}
        color="secondary"
        size={buttonSize}
        onClick={onOpen}
      >
        {t(keys.connect)}
      </Button>
    );
  }

  return (
    <Select
      classes={{ root: classes.selectRoot }}
      renderValue={() => (
        <Typography
          color={theme => theme.palette.grey[900]}
          fontWeight={500}
          variant="body2"
        >
          {cropString(address)}
        </Typography>
      )}
      startAdornment={
        walletIcon ? (
          <div className={classes.iconContainer}>
            <img alt="" className={classes.icon} src={walletIcon} />
          </div>
        ) : undefined
      }
      value={address}
      variant="standard"
    >
      <MenuItem onClick={() => disconnect()}>
        <Typography color="inherit" variant="body2">
          {t(keys.disconnect)}
        </Typography>
      </MenuItem>
    </Select>
  );
}
