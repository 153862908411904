import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    project: 'Project',
    myContribution: 'My contribution',
    apy: 'APY',
    reward: 'Reward',
  },
};
