import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { WriteProvider } from '../../api';
import IERC20Abi from './abi/IERC20';

export async function setAllowance(
  provider: WriteProvider,
  data: {
    spender: Address;
    assetAddress: Address;
    amount: BigNumber;
    investTokenDecimal: number;
  },
) {
  const { spender, assetAddress, amount, investTokenDecimal } = data;

  const investTokenContract = provider.createContract(IERC20Abi, assetAddress);

  const transactionData = investTokenContract.methods
    .approve(spender, utils.toWei(amount.toString(10), investTokenDecimal))
    .encodeABI();

  return provider.sendTransactionAsync(provider.currentAccount, assetAddress, {
    data: transactionData,
    estimate: true,
    estimateFee: true,
  });
}
