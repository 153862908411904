import { Components, Theme, typographyClasses } from '@mui/material';

export function getMuiFormLabel(theme: Theme): Components['MuiFormLabel'] {
  return {
    styleOverrides: {
      root: {
        [`& .${typographyClasses.root}`]: {
          color: theme.palette.text.primary,
        },
      },
    },
  };
}
