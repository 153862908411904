import React, { JSX, lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import { QueryLoadingAbsolute } from '../common/components/QueryLoading';
import { ProjectRoutesConfig } from './projectRoutesConfig';

const Projects = lazy(() =>
  import('./screens/Projects').then(({ Projects }) => ({
    default: Projects,
  })),
);

const Project = lazy(() =>
  import('./screens/Project').then(({ Project }) => ({
    default: Project,
  })),
);

const CreateProject = lazy(() =>
  import('./screens/CreateProject').then(({ CreateProject }) => ({
    default: CreateProject,
  })),
);

export function getProjectRoutes(): JSX.Element {
  return (
    <>
      <Route
        element={
          <Suspense fallback={<QueryLoadingAbsolute />}>
            <Projects />
          </Suspense>
        }
        path={ProjectRoutesConfig.projectsRoot.path}
      />

      <Route
        element={
          <Suspense fallback={<QueryLoadingAbsolute />}>
            <Projects />
          </Suspense>
        }
        path={ProjectRoutesConfig.projects.path}
      />

      <Route
        element={
          <Suspense fallback={<QueryLoadingAbsolute />}>
            <Project />
          </Suspense>
        }
        path={ProjectRoutesConfig.project.path}
      />

      <Route
        element={
          <Suspense fallback={<QueryLoadingAbsolute />}>
            <CreateProject />
          </Suspense>
        }
        path={ProjectRoutesConfig.createProject.path}
      />
    </>
  );
}
