import { palette as ankrPalette } from '@ankr.com/ui';
import { PaletteOptions } from '@mui/material';

export const mainPalette: PaletteOptions = {
  ...ankrPalette,
  info: {
    main: '#356DF3',
    dark: '#2A5BD1',
    light: '#E1E9FD',
    contrastText: '#ffffff',
  },
  grey: {
    ...ankrPalette.grey,
    500: '#99A9BF',
  },
  text: {
    ...ankrPalette.text,
    secondary: '#536680',
  },
};
