import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(9),
  },

  cards: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: 120,
    },
  },

  card: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 285,
    },
  },

  empty: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7.5),
    maxWidth: 'max-content',
  },
}));
