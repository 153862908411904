import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    minHeight: 474,
    color: theme.palette.common.white,
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      minHeight: 474,
    },
  },

  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },

  button: {
    minHeight: 54,
    borderRadius: 12,
    color: theme.palette.text.primary,

    marginTop: theme.spacing(8),
  },
}));
