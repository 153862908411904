export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
  const emailNormalized = String(email).trim().toLowerCase();
  const emailParts = emailNormalized.split('@');
  return (
    emailRegex.test(emailNormalized) &&
    emailParts[0].length <= 64 &&
    emailParts[1].length <= 255
  );
};

export const isValidWebsiteUrl = (url: string): boolean => {
  const urlStr = String(url).toLowerCase();
  const urlRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return urlStr.length < 2083 && urlRegex.test(urlStr);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isInteger = (value: any) => {
  return /^\d+$/i.test(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isIntegerPositive = (value: any) => {
  return isInteger(value) && Number(value) > 0;
};
