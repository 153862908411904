import { deepmerge } from '@mui/utils';

import { getReadProvider } from '../../api';
import { chainId } from '../../api/chainIDs';
import { getContribution } from '../api/getContribution';
import { getContributorCount } from '../api/getContributorCount';
import { IProjectItem } from '../api/types';

// TODO Replace by API
function getTokenName(projectName: string) {
  if (projectName === 'AI Navigator') {
    return 'NAVI';
  }

  if (projectName === 'NeuroGen AI') {
    return 'NGEN';
  }

  return 'IMO';
}

export async function addDataFromContract(item: IProjectItem) {
  const readProvider = await getReadProvider(chainId);

  if (item.attributes.projectAddress) {
    const investments = await getContribution(readProvider, {
      projectAddress: item.attributes.projectAddress,
    });

    return deepmerge(item, {
      attributes: {
        investorsCount: (
          await getContributorCount(readProvider, {
            projectAddress: item.attributes.projectAddress,
          })
        ).contributorCount,
        investedAmount: investments.amount,
        investedSymbol: investments.symbol,
        investmentGoal: investments.investmentGoal,
        investmentEndTime: investments.investmentEndTime,
        investTokenAddress: investments.investTokenAddress,
        imoTokenSymbol: getTokenName(item.attributes.projectName),
        isActive: investments.isActive,
      },
    });
  }
  return item;
}
