import { Components } from '@mui/material/styles';

export function getMuiTypography(): Components['MuiTypography'] {
  return {
    styleOverrides: {
      root: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        fontFeatureSettings: "'cv11' on, 'case' on, 'calt' off",
      },
    },
  };
}
