import { IRawAccountTier } from '@ankr.com/points';

import { api, cacheTags, getWriteProvider, PointsApi } from 'modules/api';
import { getExtendedErrorText } from 'modules/common/utils/getExtendedErrorText';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { t } from 'modules/i18n';

export const { useGetAccountTierQuery } = api.injectEndpoints({
  endpoints: build => ({
    getAccountTier: build.query<IRawAccountTier, void>({
      queryFn: queryFnNotifyWrapper<void, never, IRawAccountTier>(
        async () => {
          const api = PointsApi.getInstance();
          const provider = await getWriteProvider();

          return { data: await api.getAccountTier(provider.currentAccount) };
        },
        error => getExtendedErrorText(error, t('requestError.getAccountTier')),
      ),
      providesTags: [cacheTags.account, cacheTags.points],
    }),
  }),
});
