import BigNumber from 'bignumber.js';

import { api, cacheTags, getWriteProvider, PointsApi } from 'modules/api';
import { getExtendedErrorText } from 'modules/common/utils/getExtendedErrorText';
import { queryFnNotifyWrapper } from 'modules/common/utils/queryFnNotifyWrapper';
import { t } from 'modules/i18n';

export const { useGetPointsQuery } = api.injectEndpoints({
  endpoints: build => ({
    getPoints: build.query<BigNumber, void>({
      queryFn: queryFnNotifyWrapper<void, never, BigNumber>(
        async () => {
          const api = PointsApi.getInstance();

          const provider = await getWriteProvider();

          return { data: await api.getPoints(provider.currentAccount) };
        },
        error => getExtendedErrorText(error, t('requestError.getPoints')),
      ),
      providesTags: [cacheTags.account, cacheTags.points],
    }),
  }),
});
