import React, { JSX } from 'react';
import { OverlaySpinner } from '@ankr.com/ui';

import { useQueryLoadingStyles } from './useQueryLoadingStyles';

interface IQueryLoadingProps {
  className?: string;
  size?: number;
}

export function QueryLoadingAbsolute({
  size,
  className,
}: IQueryLoadingProps): JSX.Element {
  const { classes, cx } = useQueryLoadingStyles();

  return (
    <OverlaySpinner className={cx(classes.absolute, className)} size={size} />
  );
}
