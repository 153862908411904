import { selectClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },

  button: {
    color: theme.palette.grey[900],

    '&:focus': {
      boxShadow: 'none',
    },
  },

  selectRoot: {
    minHeight: 40,
    borderRadius: 8,

    '&&': {
      backgroundColor: theme.palette.grey[100],

      [`&:hover, &.Mui-focused`]: {
        boxShadow: 'none',
        backgroundColor: theme.palette.grey[100],
      },

      [`&& .${selectClasses.select}`]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(5),
      },
    },
  },

  iconContainer: {
    display: 'flex',
    padding: theme.spacing(1),
  },

  icon: {
    width: 20,
  },
}));
