import { Address } from 'web3';

import { ReadProvider } from '../../api';
import IERC20Abi from './abi/IERC20';
import { createImoContract } from './createImoContract';
import { memoizePromise } from './memoizePromise';

async function _getContributionToken(
  provider: ReadProvider,
  { projectAddress }: { projectAddress: string },
) {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const investTokenAddress: Address = await IMOSaleContract.methods
    .usdtToken()
    .call();

  const contract = provider.createContract<typeof IERC20Abi>(
    IERC20Abi,
    investTokenAddress,
  );

  const decimalsResponse: bigint = await contract.methods.decimals().call();

  const decimals = Number(decimalsResponse);

  const symbol = await contract.methods.symbol().call();

  return {
    decimals,
    symbol,
    investTokenAddress,
  };
}

export const getContributionToken = memoizePromise(
  _getContributionToken,
  (provider: ReadProvider, args: { projectAddress: string }) => {
    return `${provider.chainId}${provider.rpcUrl}${args.projectAddress}`;
  },
);
