import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import IERC20Abi from './abi/IERC20';
import { createImoContract } from './createImoContract';

export async function getAllowance(
  provider: ReadProvider,
  data: { userAddress: Address; spender: Address },
): Promise<{
  allowance: BigNumber;
  investTokenAddress: Address;
  investTokenDecimal: number;
}> {
  const { userAddress, spender } = data;
  const IMOSaleContract = createImoContract(provider, { address: spender });

  const investTokenAddress: Address = await IMOSaleContract.methods
    .usdtToken()
    .call();

  const investTokenContract = provider.createContract<typeof IERC20Abi>(
    IERC20Abi,
    investTokenAddress,
  );

  const allowance: bigint = await investTokenContract.methods
    .allowance(userAddress, spender)
    .call();

  const decimals: bigint = await investTokenContract.methods.decimals().call();

  return {
    allowance: new BigNumber(utils.fromWei(allowance, Number(decimals))),
    investTokenAddress,
    investTokenDecimal: Number(decimals),
  };
}
