import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    unknown: 'Unknown',
    noTier: 'No tier',
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold',

    noTier1: 'Neura Point per ANKR staked',
    noTier2: 'Can’t participate in IMOs',
    noTier3: 'No early access to IMOs',

    bronze1: 'Neura Point Multiplier',
    bronze2: 'Can participate in IMOs',
    bronze3: 'No early access to IMOs',

    silver1: 'Neura Point Multiplier',
    silver2: 'Can participate in IMOs',
    silver3: '24-hour early access to high-demand IMOs',

    gold1: 'Neura Point Multiplier',
    gold2: 'Can participate in IMOs',
    gold3: '48-hour early access to high-demand IMOs',
  },
};
