import { ReactElement } from 'react';
import { Button, Typography } from '@mui/material';

import { Background } from 'modules/dashboard/components/Background';
import { KnownDialogs, useDialog } from 'modules/dialogs';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { translation } from './translation';
import { useStyles } from './useStyles';

export function ConnectWallet(): ReactElement {
  const { classes } = useStyles();
  const { t, keys } = useTranslation(translation);

  const { onOpen } = useDialog(KnownDialogs.connect);

  return (
    <div className={classes.root}>
      <Background />

      <div className={classes.content}>
        <Typography variant="h3">{t(keys.title)}</Typography>

        <Typography sx={{ mt: 4.5 }} variant="body2">
          {t(keys.description)}
        </Typography>

        <Button className={classes.button} color="secondary" onClick={onOpen}>
          {t(keys.button)}
        </Button>
      </div>
    </div>
  );
}
