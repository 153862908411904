import { PointsApi as AnkrPointsApi } from '@ankr.com/points';

export class PointsApi {
  private static instance: AnkrPointsApi;

  public static getInstance(): AnkrPointsApi {
    if (PointsApi.instance) {
      return PointsApi.instance;
    }

    PointsApi.instance = new AnkrPointsApi({
      baseURL: import.meta.env.VITE_POINTS_API_URL,
    });

    return PointsApi.instance;
  }
}
