import { chipClasses, Components, Theme } from '@mui/material';

export function getMuiSvgIcon(theme: Theme): Components['MuiSvgIcon'] {
  return {
    styleOverrides: {
      root: {
        [`&.${chipClasses.deleteIcon}`]: {
          fill: theme.palette.grey[400],
        },
      },
    },
  };
}
