import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import { DashboardRoutesConfig } from 'modules/dashboard/Routes';
import { useTranslation } from 'modules/i18n/hooks/useTranslation';

import { MembershipRank } from '../MembershipRank';
import { MyContribution } from '../MyContribution';
import { translation } from './translation';
import { useStyles } from './useStyles';

export enum DashboardTab {
  MyContribution = 'contribution',
  MembershipRank = 'membership',
}

export function Tabs(): ReactElement {
  const { classes, cx } = useStyles();
  const { t, keys } = useTranslation(translation);
  const { tab } = DashboardRoutesConfig.dashboard.useParams();

  return (
    <div className={classes.root}>
      <div className={classes.tabs}>
        <Typography
          className={cx(
            classes.tab,
            tab === DashboardTab.MyContribution && classes.activeTab,
          )}
          component={Link}
          to={DashboardRoutesConfig.dashboard.generatePath(
            DashboardTab.MyContribution,
          )}
          variant="subtitle1"
        >
          {t(keys.myContribution)}
        </Typography>

        <Typography
          className={cx(
            classes.tab,
            tab === DashboardTab.MembershipRank && classes.activeTab,
          )}
          component={Link}
          to={DashboardRoutesConfig.dashboard.generatePath(
            DashboardTab.MembershipRank,
          )}
          variant="subtitle1"
        >
          {t(keys.memberShipRank)}
        </Typography>
      </div>

      <div className={classes.tabsContent}>
        {tab === DashboardTab.MyContribution && <MyContribution />}

        {tab === DashboardTab.MembershipRank && <MembershipRank />}
      </div>
    </div>
  );
}
