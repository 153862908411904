import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    title: 'Connect Wallet',
    description:
      'Connect wallet to see your Neura credits, stats, progress and benefits.',
    button: 'Connect Wallet',
  },
};
