import { useEffect } from 'react';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { restoreWalletId } from 'modules/auth/utils/storeWalletId';

export function useAuth(): void {
  const { connect } = useConnection();

  useEffect(() => {
    const walletId = restoreWalletId();

    if (!walletId) {
      return;
    }
    void connect(walletId);
  }, [connect]);
}
