import { makeStyles } from 'tss-react/mui';

export const useContributeModalStyles = makeStyles()(theme => ({
  text: {
    textAlign: 'center',
  },
  subTitle: {
    marginTop: theme.spacing(3),
  },
  paper: {
    maxWidth: 696,
    minHeight: 527,
    padding: 0,
    borderRadius: 16,
    boxShadow:
      '0px 5px 25px 0px rgba(31, 34, 38, 0.10), 0px 10px 50px 0px rgba(31, 34, 38, 0.10)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    padding: theme.spacing(15),
  },

  video: {
    width: 120,
    maxWidth: '100%',
    height: 120,
    display: 'block',
    margin: '0 auto',
  },

  details: {},

  listBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
      borderTop: 0,
    },
  },
  listRowLeft: {
    wordBreak: 'break-word',
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  listRowRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
  },
}));
