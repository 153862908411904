import {
  Components,
  inputBaseClasses,
  svgIconClasses,
  Theme,
} from '@mui/material';

export function getMuiAutocomplete(
  theme: Theme,
): Components['MuiAutocomplete'] {
  return {
    styleOverrides: {
      endAdornment: {
        right: theme.spacing(1),
        top: `calc(50% - ${theme.spacing(5)})`,

        [`& .${svgIconClasses.root}`]: {
          fill: theme.palette.grey[400],
        },
      },

      inputRoot: {
        [`&.${inputBaseClasses.root}`]: {
          height: 'auto',
          minHeight: 48,
          paddingTop: theme.spacing(0.5),
        },
      },
    },
  };
}
