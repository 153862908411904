import { makeStyles } from 'tss-react/mui';

export const useQueryLoadingStyles = makeStyles()(() => ({
  absolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}));
