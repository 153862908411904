import { useMemo } from 'react';
import { IPointTier, PointTier } from '@ankr.com/points';

import { useConnection } from 'modules/auth/hooks/useConnection';
import { ZERO } from 'modules/common/const';

import { useGetAccountTierQuery } from '../actions/getAccountTier';
import { useGetAnkrStakedAmountQuery } from '../actions/getAnkrStakedAmount';
import { useGetTiersQuery } from '../actions/getTiers';
import { useApplyTierAssets } from './useApplyTierAssets';
import { useApplyTierDaysLeft } from './useApplyTierDaysLeft';

function getPointTierId(id: string): PointTier | null {
  if (Object.values(PointTier).includes(id as PointTier)) {
    return id as PointTier;
  }

  return null;
}

export function useGetPointTiers(): IPointTier[] {
  const { isConnected } = useConnection();
  const { data: apiTiers } = useGetTiersQuery();
  const { data: stakedAmount = ZERO } = useGetAnkrStakedAmountQuery(undefined, {
    skip: !isConnected,
  });

  const { data: accountTierData } = useGetAccountTierQuery(undefined, {
    skip: !isConnected,
  });

  const passedTierIds = useMemo(() => {
    const ids = new Set<string>();
    if (!accountTierData || !apiTiers?.length) {
      return ids;
    }

    const { currentTier } = accountTierData;
    apiTiers.find(({ id }) => {
      if (!currentTier.includes(id)) {
        return true;
      }

      ids.add(id);

      return false;
    });

    return ids;
  }, [accountTierData, apiTiers]);

  const lastCurrentId = useMemo(() => {
    const currentTiers = accountTierData?.currentTier;
    if (!currentTiers?.length) {
      return undefined;
    }

    return currentTiers[currentTiers.length - 1];
  }, [accountTierData]);

  const holdedTierIds = useMemo(() => {
    if (!accountTierData?.pendingTiers?.length) {
      return new Set<PointTier>();
    }

    return new Set<PointTier>(accountTierData.pendingTiers);
  }, [accountTierData]);

  const holdedTierDaysLeft = useMemo(() => {
    const daysLeft = new Map<PointTier, number>();
    if (!accountTierData?.pendingTiers?.length) {
      return daysLeft;
    }

    accountTierData?.pendingTiers.forEach((id, i) => {
      const pendingSeconds = accountTierData?.pendingNeedWait?.[i];
      if (!pendingSeconds) {
        return;
      }

      const pendingDays = Math.ceil(pendingSeconds / (60 * 60 * 24));
      daysLeft.set(id, pendingDays);
    });

    return daysLeft;
  }, [accountTierData]);

  const tiers = useMemo(() => {
    return apiTiers?.length
      ? apiTiers
          .map(({ id, amount, duration, dayReward, multiplier }, i) => {
            const tierId = getPointTierId(id);
            if (!tierId) {
              return null;
            }

            const isPassed = passedTierIds.has(id);
            const isCurrent = lastCurrentId === id;

            const previousTier = apiTiers[i - 1];
            const isNext =
              lastCurrentId && previousTier
                ? previousTier.id === lastCurrentId
                : false;

            const daysDuration = Math.ceil(duration / (60 * 60 * 24));

            return {
              id: tierId,
              amount,
              daysDuration,
              daysLeft: holdedTierDaysLeft.get(id),

              pointsDayRatio: dayReward,
              multiplier,

              isNext,
              isCurrent,
              isPassed,
              isHolded: holdedTierIds.has(id),
              needToStake: amount.minus(stakedAmount),
            };
          })
          .filter((tier): tier is IPointTier => tier !== null)
      : [];
  }, [
    apiTiers,
    holdedTierDaysLeft,
    holdedTierIds,
    lastCurrentId,
    passedTierIds,
    stakedAmount,
  ]);

  const pendingTierDays = useApplyTierDaysLeft(tiers);

  return useApplyTierAssets(pendingTierDays);
}
