import { api } from 'modules/api';

import { queryFnNotifyWrapper } from '../../common/utils/queryFnNotifyWrapper';

interface ICreateGetPromiseResultArgs {
  invalidatesTags: string[];
}

export function createGetPromiseResult<T>(
  { invalidatesTags }: ICreateGetPromiseResultArgs = { invalidatesTags: [] },
) {
  return api.injectEndpoints({
    endpoints: build => ({
      waitTransactionDetails: build.mutation<T, Promise<T>>({
        queryFn: queryFnNotifyWrapper<Promise<T>, never, T>(async promise => {
          return { data: await promise };
        }),
        invalidatesTags,
      }),
    }),
  });
}
