/* eslint-disable no-template-curly-in-string */
import { Locale } from './locales';

export const globalTranslation = {
  [Locale.en]: {
    common: {
      space: ' ',
      colon: ':',
    },
    unit: {
      $: '${value}',
      tokenValue: '{value} {token}',
      percent: '{value}%',
      second: '{value}sec',
      minute: '{value}min',
      hour: '{value}hr',
      day: '{value}day',
      month: '{value}mon',
      year: '{value}year',
      secondShort: '{value}s',
      minuteShort: '{value}m',
      hourShort: '{value}h',
      dayShort: '{value}d',
      monthShort: '{value}M',
      yearShort: '{value}Y',
    },
    requestError: {
      getPoints: 'Failed to get points',
      getTiers: 'Failed to get tiers',
      getAnkrStakedAmount: 'Failed to get Ankr staked amount',
      getAccountTier: 'Failed to get account tier',
    },
  },
};
