import { generatePath } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { createRouteConfig } from 'modules/common/utils/createRouteConfig';

import { homepage } from '../../../package.json';

const PROJECTS_PATH = `/projects`;
const PROJECT_PATH = `/project/`;
const CREATE_PROJECT_PATH = `/project/create`;

export const ProjectRoutesConfig = createRouteConfig(
  {
    projectsRoot: {
      path: '/',
      generatePath: () => '/',
    },
    projects: {
      path: PROJECTS_PATH,
      generatePath: () => PROJECTS_PATH,
    },
    project: {
      path: PROJECT_PATH,
      generatePath: (projectId: string | number) =>
        generatePath(`${PROJECT_PATH}?projectId=${projectId}`),
      useParams: () => {
        const [projectId] = useQueryParam('projectId', StringParam);

        return { projectId };
      },
    },
    createProject: {
      path: CREATE_PROJECT_PATH,
      generatePath: () => CREATE_PROJECT_PATH,
    },
  },
  homepage,
);
