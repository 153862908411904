import BigNumber from 'bignumber.js';
import { Address, utils } from 'web3';

import { ReadProvider } from '../../api';
import { createImoContract } from './createImoContract';
import { getContributionToken } from './getContributionToken';

export async function getUserContributionAmount(
  provider: ReadProvider,
  {
    projectAddress,
    contributorAddress,
  }: { projectAddress: Address; contributorAddress: Address },
): Promise<{
  contributionAmount: BigNumber;
}> {
  const IMOSaleContract = createImoContract(provider, {
    address: projectAddress,
  });

  const { decimals } = await getContributionToken(provider, {
    projectAddress,
  });

  const contribution: bigint = await IMOSaleContract.methods
    .investments(contributorAddress)
    .call();

  return {
    contributionAmount: new BigNumber(
      utils.fromWei(contribution.toString(), decimals),
    ),
  };
}
