import { useMemo } from 'react';
import { IPointTier, PointTier } from '@ankr.com/points';

export function useApplyTierDaysLeft(tiers: IPointTier[]): IPointTier[] {
  return useMemo(() => {
    const newTiers: IPointTier[] = [];
    tiers.forEach((tier, i) => {
      if (tier.id === PointTier.NoTier) {
        newTiers.push({
          ...tier,
          daysLeft: 0,
        });
        return;
      }

      if (i === 0 || tier.isHolded) {
        newTiers.push({
          ...tier,
          daysLeft: tier.isPassed ? 0 : (tier.daysLeft ?? tier.daysDuration),
        });
        return;
      }

      const previousTier = newTiers[i - 1];
      const previousDaysLeft = previousTier.isPassed
        ? previousTier.daysDuration
        : (previousTier.daysLeft ?? 0);

      const previousDaysPassed = previousTier.daysDuration - previousDaysLeft;
      const handledPreviousDaysPassed = previousTier.isPassed
        ? previousTier.daysDuration
        : previousDaysPassed;

      const daysLeft = tier.daysDuration - handledPreviousDaysPassed;

      newTiers.push({
        ...tier,
        daysLeft: daysLeft < 0 ? 0 : daysLeft,
      });
    });

    return newTiers;
  }, [tiers]);
}
