import { chipClasses, Components, Theme } from '@mui/material';

export function getMuiChip(theme: Theme): Components['MuiChip'] {
  return {
    styleOverrides: {
      root: {
        [`&:has(.${chipClasses.icon})`]: {
          gap: theme.spacing(1),
        },

        [`&.${chipClasses.sizeMedium}`]: {
          height: 30,
          borderRadius: 8,

          [`& .${chipClasses.label}`]: {
            fontSize: 16,
            fontWeight: 500,
          },
        },

        [`&.${chipClasses.sizeSmall}`]: {
          borderRadius: 8,
          height: 20,
          padding: theme.spacing(0.5, 2),

          [`& .${chipClasses.label}`]: {
            fontSize: 12,
          },
        },

        [`&.${chipClasses.colorSecondary}`]: {
          '&&': {
            backgroundColor: theme.palette.grey[100],

            [`& .${chipClasses.label}`]: {
              color: theme.palette.text.secondary,
            },
          },
        },

        [`&.${chipClasses.colorPrimary}`]: {
          '&&': {
            backgroundColor: theme.palette.primary.light,

            [`& .${chipClasses.label}`]: {
              color: theme.palette.primary.main,
            },
          },
        },

        [`&.${chipClasses.colorSuccess}`]: {
          '&&': {
            backgroundColor: theme.palette.success.light,

            [`& .${chipClasses.label}, & .${chipClasses.icon}`]: {
              color: theme.palette.success.main,
            },
          },
        },

        [`&.${chipClasses.colorError}`]: {
          '&&': {
            backgroundColor: theme.palette.error.light,

            [`& .${chipClasses.label}, & .${chipClasses.icon}`]: {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
  };
}
