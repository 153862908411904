import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    height: '100%',

    position: 'absolute',
    left: 0,
    top: 0,
  },

  background: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    position: 'absolute',
    left: 0,
    top: 0,
  },

  backgroundMd: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  backgroundLg: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));
