import { makeStyles } from 'tss-react/mui';

import { HEADER_MIN_HEIGHT } from '../../const';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',

    minHeight: HEADER_MIN_HEIGHT,
    padding: theme.spacing(2, 15),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 8),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },

  link: {
    display: 'flex',
    width: 116,
  },

  links: {
    height: '100%',
    margin: '0 auto',
    width: 'max-content',
  },

  linksXs: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));
