import { Locale } from 'modules/i18n';

export const translation = {
  [Locale.en]: {
    participated: 'IMOs participated',
    myContribution: 'My contribution',
    stacked: 'ANKR Staked',
    empty:
      'Discover IMOs to find the best project worthy of your contribution.',
    discoverProjects: 'Discover IMOs',
    value: {
      participated: '{value, number}',
      // eslint-disable-next-line no-template-curly-in-string
      contribution: '${value, number, ::compact-short}',
      staked: '{value, number, ::compact-short}',
    },
  },
};
