import { Components, Theme } from '@mui/material';

export function getMuiIconButton(theme: Theme): Components['MuiIconButton'] {
  return {
    styleOverrides: {
      colorInherit: {
        borderColor: theme.palette.divider,
        color: theme.palette.divider,

        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: theme.palette.grey[400],
        },
      },
    },
  };
}
