import { Components } from '@mui/material';

export function getMuiButtonBase(): Components['MuiButtonBase'] {
  return {
    styleOverrides: {
      root: {
        height: 'auto',
      },
    },
  };
}
