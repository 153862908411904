import { Components, svgIconClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

const MENU_ITEM_MAX_WIDTH = 190;

export function getMuiMenuItem(theme: Theme): Components['MuiMenuItem'] {
  return {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        borderRadius: 12,
        minWidth: MENU_ITEM_MAX_WIDTH,

        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          [`${svgIconClasses.root}`]: {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  };
}
