import { Components, paperClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function getMuiPaper(theme: Theme): Components['MuiPaper'] {
  return {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(1),

        [`&.${paperClasses.rounded}`]: {
          borderRadius: 16,
        },

        [`&.${paperClasses.elevation1}`]: {
          boxShadow:
            '0px 2px 5px 0px rgba(31, 34, 38, 0.10), 0px 3px 15px 0px rgba(31, 34, 38, 0.10)',
        },
      },
    },
  };
}
