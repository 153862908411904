import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    padding: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },

  board: {
    minHeight: 380,
  },

  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },

  label: {
    maxWidth: 896,
    lineHeight: '135%',
  },
}));
