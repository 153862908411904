import { makeStyles } from 'tss-react/mui';

import { CONTENT_MAX_WIDTH } from '../../const';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },

  content: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    maxWidth: CONTENT_MAX_WIDTH,
    width: '100%',
    padding: theme.spacing(15),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));
