import BigNumber from 'bignumber.js';
import { isFuture, isPast } from 'date-fns';

import { PROJECT_STATUS } from '../api/types';

interface IGetProjectStatusProps {
  endDate?: Date;
  raised?: BigNumber;
  total?: BigNumber;
  isActive?: boolean;
}

export const getProjectStatus = ({
  endDate,
  raised = new BigNumber(0),
  total,
  isActive,
}: IGetProjectStatusProps): PROJECT_STATUS => {
  if (!!raised && !!total && raised.isGreaterThanOrEqualTo(total)) {
    return PROJECT_STATUS.soldOut;
  }
  if (!isActive && endDate && isFuture(endDate)) {
    return PROJECT_STATUS.comingSoon;
  }
  if (!!endDate && isPast(endDate)) {
    return PROJECT_STATUS.closed;
  }
  if (isActive && !!endDate && isFuture(endDate)) {
    return PROJECT_STATUS.active;
  }
  return PROJECT_STATUS.comingSoon;
};
