import { alpha, Components, Theme } from '@mui/material';

export function getMuiBackdrop(theme: Theme): Components['MuiBackdrop'] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.background.paper, 0.5),
        backdropFilter: 'blur(16px)',
      },
      invisible: {
        backdropFilter: 'none',
      },
    },
  };
}
