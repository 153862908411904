import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
  },

  cards: {
    [theme.breakpoints.up('md')]: {
      minHeight: 380,
    },
  },
}));
