/* eslint-disable camelcase */
import BigNumber from 'bignumber.js';
import { Address } from 'web3';

export const PROJECT_STATUS = {
  comingSoon: 'comingSoon',
  active: 'active',
  soldOut: 'soldOut',
  closed: 'closed',
};
export type PROJECT_STATUS =
  (typeof PROJECT_STATUS)[keyof typeof PROJECT_STATUS];

export interface IProjectPayload {
  projectName: string;
  projectContactName: string;
  projectContactTelegram: string;
  projectContactEmail: string;
  projectReferredBy: string;
  projectWebsiteURL: string;
  projectXURL: string;
  projectPitchdeckURL: string;
  projectTokenScheduleURL: string;
  projectOtherURL?: string;
  projectStartDate: string;
  projectDescription?: string;
  projectEcosystemDescription?: string;
  projectTeamDescription?: string;
  projectTeamMembersAmount?: string;
  projectCommunityDescription?: string;
  projectUsersAmount?: string;
  projectTokensPublicAmount: string | number;
  projectTokensAmountToBeRaised: string | number;
  projectExpectedParticipants: string | number;
  projectTokensIsFlexible: boolean | 'true' | '';
  projectTokensPlannedFDVAtLaunch: string | number;
  projectTokensSalesDeadline: string;
  projectAddInfo?: string;
  publishedAt?: Date | null;
}

interface IGallery {
  id: number;
  attributes: {
    name: string;
    alternativeText: string | null;
    caption: string | null;
    width: number;
    height: number;
    formats: unknown | null;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string | null;
    provider: string;
    provider_metadata: unknown | null;
    createdAt: string;
    updatedAt: string;
  };
}

export interface ICompanyAttributes {
  companyName?: string;
  companyLocation?: string;
  companyDescription?: string;
  companyTwitterURL?: string;
  companyInstagramURL?: string;
  companyRedditURL?: string;
  companyLogo?: {
    data: IGallery | null;
  };
}

export interface ICompany {
  id: number;
  attributes: ICompanyAttributes;
}

interface IProjectAttributesFromApi {
  createdAt: string;
  updatedAt?: string;
  publishedAt?: string | null;
  projectName: string;
  projectContactName: string;
  projectContactTelegram: string;
  projectContactEmail: string;
  projectReferredBy: string;
  projectWebsiteURL: string;
  projectXURL: string;
  projectPitchdeckURL: string;
  projectTokenScheduleURL: string;
  projectOtherURL?: string;
  projectStartDate: string;
  projectDescription?: string;
  projectEcosystemDescription?: string;
  projectTeamDescription?: string;
  projectTeamMembersAmount?: string;
  projectCommunityDescription?: string;
  projectUsersAmount?: string;
  projectTokensPublicAmount: string | number;
  projectTokensAmountToBeRaised: string | number;
  projectExpectedParticipants: string | number;
  projectTokensIsFlexible: boolean | 'true' | '';
  projectTokensPlannedFDVAtLaunch: string | number;
  projectTokensSalesDeadline: string;
  projectAddInfo?: string;
  projectAddress?: Address;
  projectGallery?: {
    data: IGallery[] | null;
  };
  projectCompany?: {
    data: ICompany | null;
  };
}

interface IProjectAttributes {
  createdAt: Date;
  updatedAt?: Date;
  publishedAt?: Date;
  projectName: string;
  projectContactName: string;
  projectContactTelegram: string;
  projectContactEmail: string;
  projectReferredBy: string;
  projectWebsiteURL: string;
  projectXURL: string;
  projectPitchdeckURL: string;
  projectTokenScheduleURL: string;
  projectOtherURL?: string;
  projectStartDate: Date;
  projectDescription?: string;
  projectEcosystemDescription?: string;
  projectTeamDescription?: string;
  projectTeamMembersAmount?: string;
  projectCommunityDescription?: string;
  projectUsersAmount?: string;
  projectTokensPublicAmount?: number;
  projectTokensAmountToBeRaised?: number;
  projectExpectedParticipants?: number;
  projectTokensIsFlexible: boolean;
  projectTokensPlannedFDVAtLaunch?: number;
  projectTokensSalesDeadline: Date;
  projectAddInfo?: string;
  projectAddress?: Address;
  projectGallery?: {
    data: IGallery[] | null;
  };
  projectCompany?: {
    data: ICompany | null;
  };
  investorsCount: number;
  investedAmount: BigNumber;
  investedSymbol: string;
  investmentGoal: BigNumber;
  investmentEndTime: Date;
  investTokenAddress: Address;
  imoTokenSymbol: string;
  isActive: boolean;
}

export interface IProjectFromApi {
  id: number;
  attributes: IProjectAttributesFromApi;
}

export interface IProjectItem {
  id: number;
  status?: PROJECT_STATUS;
  attributes: IProjectAttributes;
}

interface IProjectMeta {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
}

export interface IProjectApiResponse {
  data: IProjectFromApi;
  meta?: IProjectMeta;
}

export interface IProjectsApiResponse {
  data: IProjectFromApi[];
  meta?: IProjectMeta;
}

export interface IProjects {
  data: IProjectItem[];
  meta?: IProjectMeta;
}
